<template>
  <div class="page-404-container container-max-width">
    <div class="container-404">
      <div class="text-center">
        <span class="text-status-error">
          {{ errorStateService.status }}
        </span>
        <h1 class="px-3" style="font-size: 50px; font-weight: bold">
          {{ errorStateService.title }}
        </h1>
        <p class="fs-6 px-3">
          {{ errorStateService.message }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('qec', ['errorStateService']),
  },
}
</script>
<style>
.page-404-container {
  width: 100%;
}
.text-status-error {
  font-size: 300px;
  color: #9c8399;
  font-weight: bold;
}
@media (max-width: 768px) {
  .text-status-error {
    font-size: 150px;
  }
}
</style>
